:root {
  --red: hsla(346, 84%, 61%, 1);
  --yellow: hsla(42, 100%, 70%, 1);
  --green: hsla(164, 95%, 43%, 1);
  --translucent-green: hsla(164, 95%, 43%, 0.1);
  --translucent-red: hsla(346, 84%, 61%, 0.1);
  --blue: hsla(195, 83%, 38%, 1);
  --light-blue: hsla(195, 83%, 58%, 1);
  --dark-blue: hsla(195, 83%, 16%, 1);
  --dark-blue2: rgb(17, 81, 102);
  --text-light: hsla(196, 23%, 77%, 1);
  --text-dark: hsla(197, 23%, 12%, 1);
  --bg-color: #222;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #222;
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  border: 1px solid var(--blue);
  background-color: var(--dark-blue);
  border-radius: 2rem;
  padding: 1rem;
  margin: 1rem;
  color: var(--text-light);
}

ul {
  list-style-type: none;
  padding-left: 10px;
}

ul li {
  border: 1px solid var(--blue);
  background-color: var(--dark-blue);
  border-radius: 2rem;
  padding: 1rem;
  margin: 1rem;
  color: var(--text-light);
}

ul li:hover {
  background-color: var(--dark-blue2);
  cursor: pointer;
}

ul li.correct {
  border-color: var(--green);
  background-color: var(--translucent-green);
}

ul li.wrong {
  border-color: var(--red);
  background-color: var(--translucent-red);
}

li:before {
  position: absolute;
  margin-left: -48px;
  line-height: 100%;
}

ul li.selected:before {
  content: "➤";
  font-size: 1.6rem;
}

ul li.selected.correct:before {
  color: var(--green);
  content: "✓";
  margin-top: -12px;
  font-size: 2rem;
}

ul li.selected.wrong:before {
  color: var(--red);
  font-size: 2.2rem;
  margin-top: -7px;
  content: "×";
}

ul li b {
  color: var(--yellow);
}

button {
  min-width: 300px;
  font-size: 1.4rem;
  border: 2px solid var(--blue);
  background-color: var(--dark-blue);
  border-radius: 2rem;
  padding: 1rem;
  margin: 1rem;
  color: var(--text-light);
}

button:disabled {
  opacity: 0.5;
}

button:hover:not(:disabled) {
  font-weight: bold;
  cursor: pointer;
}

input {
  border: 1px solid var(--blue);
  background-color: var(--dark-blue);
  border-radius: 1rem;
  padding: 0.5rem;
  margin: 0.5rem;
  margin-left: 1rem;
  color: var(--text-light);
  width: 80%;
  font-size: 1.4rem;
  font-family: monospace;
  outline: none;
}

input:focus {
  border-color: var(--light-blue);
}

.ordinal {
  color: var(--text-light);
  margin-left: 1rem;
}

.center {
  text-align: center;
}

h3.correct {
  color: var(--green);
}

h3.wrong {
  color: var(--red);
}
